
import Vue from "vue";
import { validateFields } from "@/helpers";
import { personalLiabilityPolicyForm } from "@/forms/shared/personalLiabilityPolicy";
import { objectDifference } from "@/helpers";
import * as types from "@/store/mutation-types";
import { FormBlock } from "@/components/FormBuilder/types";
import {
  personalLiabilityPolicyMapActions,
  personalLiabilityPolicyMapGetters,
  personalLiabilityPolicyMapMutations,
  personalLiabilityPolicyMapState
} from "@/store/modules/personalLiabilityPolicy";
import { getPersonalLiabilityPoliciesDefault } from "@/helpers/defaultPersonalLiabilityPolicy";

interface IPLApplication {
  errorMessage: string;
  successMessage: string;
  disablePrimaryButton: boolean;
  personalLiabilityPolicyForm: FormBlock[];
  pageDataInitialized: boolean;
  resourceNotFoundMsg: string;
  loadingText: string;
  selectedStatus: string;
}
import ViewPersonalLiabilityPolicyApplication from "@/views/admin/personalLiabilityPolicy/SinglePersonalLiabilityPolicy/ViewPersonalLiabilityPolicyApplication.vue";
import { get } from "lodash";
import { downloadPersonalLiabilityDocument } from "@/helpers/downloadLossForm";

export default Vue.extend({
  name: "PLApplication",
  components: {
    ViewPersonalLiabilityPolicyApplication,
    ChangeApplicationStatus: () =>
      import("@/components/ChangeRequests/ChangeRequestStatus.vue")
  },
  data(): IPLApplication {
    return {
      errorMessage: "",
      successMessage: "",
      disablePrimaryButton: true,
      personalLiabilityPolicyForm: [],
      pageDataInitialized: false,
      resourceNotFoundMsg: "Ooops!!! Resource Not Found",
      loadingText: "",
      selectedStatus: ""
    };
  },
  created() {
    this.initializePageData();
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    ...personalLiabilityPolicyMapState(["editing", "makingApiRequest"]),
    ...personalLiabilityPolicyMapGetters(["getPersonalLiabilityPolicyById"]),
    updatedFields(): any {
      const edited = this.editing;
      const original = getPersonalLiabilityPoliciesDefault(
        this.getPersonalLiabilityPolicyById(this.personalLiabilityPolicyId)
      );
      return edited && original ? objectDifference(edited, original) : {};
    },
    personalLiabilityPolicyId(): string {
      return this.$route.params.id;
    },
    isAdmin(): boolean {
      return (
        this.$getCurrentUser &&
        ["admin", "underwriting"].includes(this.$getCurrentUser.role)
      );
    },
    primaryButton(): any {
      if (this.isAdmin) {
        return {
          text: "Save",
          key: "edit",
          disabled: Object.keys(this.updatedFields || {}).length === 0,
          loading: this.makingApiRequest
        };
      } else {
        return {
          text: "Print",
          key: "print",
          loading: this.makingApiRequest
        };
      }
    },
    updatePolicyData(): FormBlock[] {
      const companyNumber = get(this.editing, "companyNumber", "");
      return personalLiabilityPolicyForm(companyNumber);
    }
  },
  methods: {
    ...personalLiabilityPolicyMapActions([
      "getPersonalLiabilityPolicy",
      "updatePersonalLiabilityPolicy"
    ]),
    ...personalLiabilityPolicyMapMutations({
      editField: types.SET_EDIT_FIELD,
      ["SET_EDIT"]: "SET_EDIT"
    }),
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "edit":
          await this.submit();
          break;
        case "print":
          await this.printApplication();
          break;
        case "cancel":
          this.$router.push("/personal-liability-policies").catch(() => {});
          break;
      }
    },
    async submit(): Promise<void> {
      try {
        this.loadingText = "Saving data. Please wait...";
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid) {
          await this.updatePersonalLiabilityPolicy({
            id: this.personalLiabilityPolicyId,
            update: this.updatedFields
          });
          this.$router.replace("/personal-liability-policies");
          this.SET_EDIT({} as any);
        } else {
          throw new Error(
            "Form invalid. Please check the fields for missing values."
          );
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.loadingText = "";
        this.$bugSnagClient.notify(err);
      }
    },
    async initializePageData() {
      try {
        this.loadingText = "Loading data. Please wait...";
        await this.getPersonalLiabilityPolicy(this.personalLiabilityPolicyId);
        this.selectedStatus = get(this.editing, "status", "");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.pageDataInitialized = true;
        this.loadingText = "";
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    },
    formFieldChangeHandler({ key, value }: { key: any; value: any }): void {
      const addressKeys = [
        "applicantMailingAddress",
        "agencyMailingAddress",
        "locationOfProperty"
      ];
      if (addressKeys.includes(key)) {
        const { fieldKey, value: fieldValue } = value;
        this.editField({
          key: `${key}.${fieldKey}` as any,
          value: fieldValue
        });
      } else {
        this.editField({
          key,
          value
        });
      }
    },
    async changeStatus(action: string): Promise<void> {
      try {
        this.selectedStatus = action;
        this.editField({
          key: "status",
          value: action
        });
      } catch (e) {
        this.selectedStatus = get(this.editing, "status", "");
        this.$bugSnagClient.notify(e);
      }
    },
    async printApplication() {
      if (this.loadingText) return;
      this.loadingText = "Printing application. Please wait...";
      try {
        await downloadPersonalLiabilityDocument({
          _ids: [this.editing?._id]
        });
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
      }
    }
  }
});

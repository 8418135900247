
import { personalLiabilityPolicyQuestions } from "@/forms/shared/personalLiabilityPolicy";
import { getUserCompanies } from "@/helpers/companies";
import { getPLExpiryDate } from "@/helpers/defaultPersonalLiabilityPolicy";
import { sameAsPhysicalAddressChangedHandler } from "@/helpers/isSameAsPhysicalAddress";
import { agencyMapState } from "@/store/modules/agency";
import { personalLiabilityPolicyMapMutations } from "@/store/modules/personalLiabilityPolicy";
import * as types from "@/store/mutation-types";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  data(): any {
    return {
      disablePrimaryButton: true,
      disableRiskButton: true
    };
  },
  methods: {
    ...personalLiabilityPolicyMapMutations({
      editField: types.SET_EDIT_FIELD,
      ["SET_EDIT"]: "SET_EDIT"
    }),
    formFieldChangeHandler({ key, value }: { key: any; value: any }): void {
      const addressKeys = [
        "applicantMailingAddress",
        "agencyMailingAddress",
        "locationOfProperty"
      ];
      const formFields = [
        "agentCode",
        "phone",
        "fax",
        "agencyName",
        "effectiveDate",
        "expiryDate",
        "applicantName",
        "spouseName"
      ];
      if (addressKeys.includes(key)) {
        const { fieldKey, value: fieldValue } = value;
        if (fieldKey.includes("isSameAsPhysicalAddress")) {
          let targetKey = "";
          let targetValue = {};
          if (key === "agencyMailingAddress") {
            targetKey = "locationOfProperty";
            targetValue = this.editing?.agencyMailingAddress;
          } else if (key === "applicantMailingAddress") {
            targetKey = "applicantMailingAddress";
            targetValue = this.editing?.locationOfProperty;
          }
          sameAsPhysicalAddressChangedHandler(
            targetKey,
            fieldValue,
            fieldValue ? targetValue || {} : {},
            this.editField
          );
        }
        this.editField({
          key: `${key}.${fieldKey}` as any,
          value: fieldValue
        });
        if (
          key === "locationOfProperty" &&
          this.editing &&
          this.editing.applicantMailingAddress?.isSameAsPhysicalAddress
        ) {
          this.editField({
            key: `applicantMailingAddress.${fieldKey}` as any,
            value: fieldValue
          });
        }
      } else if (formFields.includes(key)) {
        if (key === "effectiveDate") {
          this.editField({
            key: "expiryDate",
            value: getPLExpiryDate(value)
          });
        }
        this.editField({
          key,
          value
        });
      } else {
        let preamble = "" as any;
        if (key === "acknowledgementOfInsuredQuestioning") {
          preamble =
            "Agent attests that the above questions were asked of the applicant?";
        } else {
          const companyNumber = get(this.editing, "companyNumber", "");
          const question = personalLiabilityPolicyQuestions(companyNumber).find(
            (question: any) => question.properties.key === key
          );
          preamble = question?.properties.preamble;
        }
        if (
          [
            "viciousAnimalAtLocation",
            "locationHasWrittenAtlasTdpPolicy",
            "smokeDetectorsAtLocation"
          ].includes(key)
        ) {
          this.disableRiskButton =
            this.editing.viciousAnimalAtLocation === "YES DECLINE RISK" ||
            this.editing.locationHasWrittenAtlasTdpPolicy ===
              "NO DECLINE RISK" ||
            this.editing.smokeDetectorsAtLocation === "NO DECLINE RISK";
        }
        const response = {
          question: preamble,
          answer: value
        };
        this.editField({
          key: `questionnaire.${key}` as any,
          value: response
        });
        this.editField({
          key,
          value
        });
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    }
  },
  computed: {
    ...agencyMapState(["editing", "makingApiRequest"]),
    getCompanies(): any {
      const initData = this.$store.state.auth["initData"];
      const companiesData =
        this.$getCurrentUser &&
        ["admin", "underwriting", "claims", "billing", "dataEntry"].includes(
          this.$getCurrentUser.role
        )
          ? get(initData, "companies")
          : get(this.$getCurrentUser, "companiesData", []);
      return getUserCompanies("plApplication", companiesData);
    }
  }
});

import * as types from "@/store/mutation-types";
import { InterfaceMap } from "@/store/helpers";
import { IAddress, ILinks } from "@/types";
import { IActionQueryPayload } from "@/store/types";

export interface PersonalLiabilityPolicyState {
  editing: Partial<IPersonalLiabilityPolicy> | null;
  makingApiRequest: boolean;
  lastUpdate: Date;
  personalLiabilityPolicies: any[];
  links: ILinks | null;
}

export type StateKeys = keyof PersonalLiabilityPolicyState;

export interface ISetStatePayload {
  key: StateKeys;
  value: any;
}

export type IPersonalLiabilityPaymentLimit =
  | "$25,000/500    PREMIUM $45.00"
  | "$100,000/500   PREMIUM $60.00"
  | "$300,000/500   PREMIUM $75.00"
  | "$25,000/1000    PREMIUM $40.00"
  | "$50,000/1000    PREMIUM $50.00"
  | "$100,000/1000   PREMIUM $75.00"
  | "$300,000/1000   PREMIUM $150.00";

export type YES = "YES";
export type NO = "NO";
export type NO_DECLINE_RISK = "NO_DECLINE_RISK";
export type YES_DECLINE_RISK = "YES_DECLINE_RISK";

export const PersonalLiabilityPaymentLimitArrForAtlas: IPersonalLiabilityPaymentLimit[] = [
  "$25,000/500    PREMIUM $45.00",
  "$100,000/500   PREMIUM $60.00",
  "$300,000/500   PREMIUM $75.00"
];

export const PersonalLiabilityPaymentLimitArrForColonial: IPersonalLiabilityPaymentLimit[] = [
  "$25,000/1000    PREMIUM $40.00",
  "$50,000/1000    PREMIUM $50.00",
  "$100,000/1000   PREMIUM $75.00",
  "$300,000/1000   PREMIUM $150.00"
];

export type InsuredLocationStatus =
  | "OWNER OCCUPIED"
  | "RENTED TO OTHER BY THE NAMED INSURED";

export const InsuredLocationStatusArr = [
  "OWNER OCCUPIED",
  "RENTED TO OTHER BY THE NAMED INSURED"
];
export const riskDeclineYesQuestionArr: string[] = ["YES DECLINE RISK", "NO"];
export const riskDeclineNoQuestionArr: string[] = ["YES", "NO DECLINE RISK"];
export type IPLStatus = "submitted" | "received" | "rejected";

export interface IPersonalLiabilityPolicy {
  deleted: boolean;
  agentCode: string;
  phone: string;
  fax: string;
  applicantName: string;
  spouseName: string;
  agencyName: string;
  approvalCode: string;
  applicantMailingAddress: Partial<IAddress>;
  agencyMailingAddress: Partial<IAddress>;
  locationOfProperty: Partial<IAddress>;
  effectiveDate: Date;
  expiryDate: Date;
  modifiedOn: Date;
  createdOn: Date;
  companyNumber: number;
  status: IPLStatus;

  //Questions
  personalLiabilityLimit: IPersonalLiabilityPaymentLimit;
  insuredLocationStatus: InsuredLocationStatus;
  businessOrDayCareAtLocation: YES | NO;
  smokeDetectorsAtLocation: YES | NO_DECLINE_RISK;
  swimmingPoolAtLocation: YES | NO;
  //pool questions
  poolFencedToLocalBuildingCode: YES | NO_DECLINE_RISK;
  poolHasDivingBoardOrSlide: YES | NO;

  viciousAnimalAtLocation: YES_DECLINE_RISK | NO;

  locationHasWrittenAtlasTdpPolicy: YES | NO;
  atlasTdpPolicyNumber: string; //if has written tdp policy

  acknowledgementOfInsuredQuestioning: boolean;
  _id: string;
  questionnaire: {
    personalLiabilityLimit: IPersonalLiabilityPaymentLimit;
    insuredLocationStatus: InsuredLocationStatus;
    businessOrDayCareAtLocation: YES | NO;
    smokeDetectorsAtLocation: YES | NO_DECLINE_RISK;
    swimmingPoolAtLocation: YES | NO;
    //pool questions
    poolFencedToLocalBuildingCode: YES | NO_DECLINE_RISK;
    poolHasDivingBoardOrSlide: YES | NO;

    viciousAnimalAtLocation: YES_DECLINE_RISK | NO;

    locationHasWrittenAtlasTdpPolicy: YES | NO;
    atlasTdpPolicyNumber: string; //if has written tdp policy
    acknowledgementOfInsuredQuestioning: boolean;
  };
}

export interface ISetIPersonalLiabilityPolicyEditField {}

export interface PersonalLiabilityPolicyMutations {
  [types.SET_STATE]: ISetStatePayload;
  [types.SET_STATES]: Partial<InterfaceMap<PersonalLiabilityPolicyState>>;
  [types.SET_ITEM]: IPersonalLiabilityPolicy | IPersonalLiabilityPolicy[];
  [types.SET_EDIT]: string | IPersonalLiabilityPolicy;
  [types.SET_EDIT_FIELD]: { key: keyof IPersonalLiabilityPolicy; value: any };
  [types.DELETE_ITEM]: string;
  [types.CLEAR_PERSONAL_LIABILITY_POLICY_STORE]: PersonalLiabilityPolicyState;
}

export interface PersonalLiabilityPolicyActions {
  createPersonalLiabilityPolicy: any;
  updatePersonalLiabilityPolicy: {
    id: string;
    update: Partial<IPersonalLiabilityPolicy>;
  };
  getPersonalLiabilityPolicy: string;
  getPersonalLiabilityPolicies: IActionQueryPayload;
  deletePersonalLiabilityPolicy: string;
}

export interface PersonalLiabilityPolicyGetters {
  getTotalRecords: number;
  getPersonalLiabilityPolicyById: (id: string) => IPersonalLiabilityPolicy;
}
